import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import Logo from "./assets/images/trustbadge.png";
import { AppProvider } from "@shopify/shopify-app-remix/react";
import { Card, EmptyState, Layout, Page } from "@shopify/polaris";

export default function App() {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  Sentry.captureRemixErrorBoundaryError(error);
  return (
    <html>
      <head>
        <title>Something went wrong</title>
        <Meta />
        <Links />
      </head>
      <body>
        <AppProvider>
          <Page>
            <Layout>
              <Layout.Section>
                <Card>
                  <EmptyState
                    heading="Something went wrong"
                    action={{ content: "Home", url: "/app" }}
                    image={Logo}
                  ></EmptyState>
                </Card>
              </Layout.Section>
            </Layout>
          </Page>
        </AppProvider>
        <Scripts />
      </body>
    </html>
  );
};
